<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('logisticInventoryTransferFilter')"
                  >
                    <CIcon v-if="!loading" size="lg" name="cil-filter" class="mr-2"/>
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right">
              <CButton color="success" square size="sm"
               @click="openModalEvent('add', 'logisticTransferForm', null, 'Yeni Talep Oluşturma Ekranı')"
              >
                Talep Oluştur
              </CButton>&nbsp;
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="logisticInventoryTransfer"
            :fields="fields"
            :items-per-page="10"
            :active-page="1"
            :loading="loading"
            pagination
            hover
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="detailModal = true, logisticInventoryDetail(selectedInventoryTransfer.id)"
            v-on:refresh="filterLogisticInventoryTransfers"
          >
            <template #show_details="{item, index}">
              <td class="py-2">
                <CDropdown
                  toggler-text="İşlemler"
                  class="m-2"
                  color="info"
                >
                  <CDropdownItem @click="openModalEvent('list', 'logisticTransferDetailList', item, 'Lojistik Stok Transfer Detay Ekranı')">Detay</CDropdownItem>
                  <CDropdownItem @click="openModalEvent('add', 'logisticTransferControlForm', item, 'Transfer Kontrol Ekranı')">
                    Transfer Kontrol
                  </CDropdownItem>
                  <CDropdownItem :disabled="item.transferAmount>0" 
                    @click="openModalEvent('cancel', 'confirm', item, 'Talebi İptal Et', item.id+' id li talebi iptal etmek üzeresin. Bunu yapmak istediğinden emin misin? ')">
                    İptal Et
                  </CDropdownItem>
                </CDropdown>
              </td>
            </template>
            <template #requestTime="{item}">
              <td> {{ item.requestTime && dateFormat(item.requestTime)}}</td>
            </template>
            <template #transferTime="{item}">
              <td> {{ item.transferTime && dateFormat(item.transferTime)}}</td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      v-if="form == 'confirm'"
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="crud"
      :desc="modalDesc"
      :actionType="actionType"
      :data="modalProps"
      :loading="loading"
    />
    <ListModal
      v-else-if="actionType == 'list'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :module="module"
      :form="form"
      :data="modalProps"
      :additionalButtons = "additionalButtons"
      :additionalEvent = "additionalEvent"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="crud"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
      :loading="loading"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterLogisticInventoryTransfers"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>

<script>
import FilterSidebar from '../components/filterSidebar'
import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'
import ListModal from '../components/listModal'
import { prepareFilterParams } from "@/helpers/custom"
import moment from "moment";

export default {
  name: 'LogisticInventoryTransfer',
  components: { FilterSidebar, FormModal, ConfirmModal, ListModal },
  data() {
    return {
      modalTitle: '',
      actionType: '',
      module: 'inventory',
      form: 'logisticTransferForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      lastItem: {},
      openSidebar: false,
      filterForm: 'logisticInventoryTransferFilter',
      filterParams: {},
      additionalButtons: [],
      activePage: 1,
      fields: [
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        },
        {key: 'id', label: 'Id',_style: 'font-size:12px'},
        {key: 'requestTime', label: 'İstek Zamanı',_style: 'font-size:12px'},
        {key: 'transferTime', label: 'Transfer Zamanı',_style: 'font-size:12px'},
        {key: 'description', label: 'Açıklama',_style: 'font-size:12px'},
        {key: 'requestAmount', label: 'İstek Adet',_style: 'font-size:12px'},
        {key: 'transferAmount', label: 'Transfer Adet',_style: 'font-size:12px'},
        {key: 'goodsAmount', label: 'Mal Adedi',_style: 'font-size:12px'},
        {key: 'goodsTotal', label: 'Mal Tutarı',_style: 'font-size:12px'}
      ],
      filters: [{
        type: 'string',
        dataIndex: 'id',
        field: 'id',
        comparison: 'eq'
      }]
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
  },
  computed: {
    reloadParams() {
      return [
        this.activePage
      ]
    },
    logisticInventoryTransfer(){
      return this.$store.getters.logisticInventoryTransfer
    },
    loading(){
      return this.$store.getters.inventoryLoading
    }
  },
  methods: {
    rowClicked(item, index) {
      this.lastItem = item;
    },
    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },
    async openModalEvent(actionType, form, data, title, desc){
      if(actionType == 'list'){
        this.additionalButtons=[
          {name: 'Ürün Barkod ve Adet Kopyala', color: 'dark', action: ['barcode', 'amount']},
          {name: 'Ürün Kodu Kopyala', color: 'dark', action: ['logisticsCode']},
          {name: 'SKU Kod Kopyala', color: 'dark', action: ['skuCode']},
          {name: 'Ürün Tipi Kopyala', color: 'dark', action: ['productTypeName']},
          {name: 'Adet Kopyala', color: 'dark', action: ['amount']}
        ]
        await this.$store.dispatch('logisticInventoryTransfer_detail', {transferId: data.id})
      }
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },
    dateFormat(date){
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },
    additionalEvent(item, action){
      let copyText = '';
      this.$store.getters.logisticInventoryTransferDetail.map((list, i) => {
        action && action.map(a =>  copyText += list[a]+' ')
        copyText += '\n'
      })

      const element = document.createElement('textarea');
      element.value = copyText;
      document.body.appendChild(element);
      element.focus();
      element.setSelectionRange(0, element.value.length);
      document.execCommand('copy');
      document.body.removeChild(element);

      const toast = {
        msg: 'Kopyalama işlemi gerçekleştirildi.',
        header: 'Başarılı',
        color: 'success'
      }
      this.$store.commit('showToast', toast)

    },
    async crud(item, action ){
      let params = {'id' : item.id ? item.id : 0}
      if(this.form == 'logisticTransferForm'){
        params.numSaleDay= item.numSaleDay
        await this.$store.dispatch('logisticInventoryTransfer_action',params)
      }else if(this.form == 'logisticTransferControlForm'){
        params.parcelCodes = item.parcelCodes
        await this.$store.dispatch('logisticInventoryTransfer_control',params)
      }else if(this.form == 'confirm'){
        await this.$store.dispatch('logisticInventoryTransfer_cancel', params)
      }
      if(this.$store.getters.inventoryStatus.success){
        this.openModalEvent()
        this.filterLogisticInventoryTransfers(this.filterParams)
      }

    },
    async filterLogisticInventoryTransfers(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)
      
      await this.$store.dispatch('logisticInventoryTransfer_list', formData)

      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },
  },
  mounted() {
    this.filterLogisticInventoryTransfers(this.filterParams);
  }
}
</script>
<style>
input,
select {
  padding: 0.45em 0.5em;
  font-size: 100%;
  border: 1px solid #ccc;
  width: 100%;
}
</style>
